.album-page {
    text-align: center;
    font-family: "Arial", sans-serif;
    background: linear-gradient(to right, #f8f9fa, #e9ecef);
    padding: 40px;
}

.album-page h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #343a40;
    font-weight: bold;
}

.photo-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    overflow: hidden;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    background: #fff;
    position: relative;
    transition: transform 0.5s ease-in-out;
}

.photo-item {
    flex: 1 0 33%; /* Ensure items take up equal space */
    transform: scale(1);
    transition: transform 0.5s ease-in-out;
    position: relative;
    opacity: 0.8;
    overflow: hidden;
}

.photo-item img {
    width: 100%; /* Ensure images fill the container properly */
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.photo-item:hover {
    transform: scale(1.05);
    z-index: 1;
    opacity: 1;
}

/* Carousel controls */
.carousel {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; /* Ensure it stretches across the entire width */
    z-index: 10;
    display: flex;
    justify-content: space-between; /* Buttons placed on the left and right */
    padding: 0 10px;
    pointer-events: none; /* Prevent buttons from blocking image clicks */
}

/* Style for carousel buttons */
.carousel-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 2em;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s;
    pointer-events: all; /* Ensure buttons are clickable */
}

.carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.carousel-button:focus {
    outline: none;
}

/* Optional: Additional styling for the hover effect on the buttons */
.carousel-button:active {
    background-color: rgba(0, 0, 0, 0.9);
}
