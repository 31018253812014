/* Default style: hide the image */
.mobile-only {
    display: none;
}

/* Show the image only for screens with max width of 768px (mobile devices) */
@media screen and (max-width: 768px) {
    .mobile-only {
        display: block; /* Make the image visible */
    }
}

.responsive-img {
    width: 100%; /* Make the image take the full width of its container */
    height: auto; /* Maintain aspect ratio */
}
