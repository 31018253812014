/* Testimonials.css */

.testimonial-area { 
  background-color: #f9f9f9;
  padding: 60px 0;
}

.banner_area .banner_inner .banner_content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.banner_area .banner_inner .banner_content p {
  font-size: 1.1rem;
  color: #666;
}

.testimonial-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #fff;
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.testimonial-photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.testimonial-feedback {
  font-size: 1rem;
  color: #555;
  margin-top: 15px;
  font-style: italic;
}

.testimonial-name {
  font-size: 1rem;
  color: #007bff; /* Bootstrap primary color */
}

/* Star Ratings */
.testimonial-stars {
  color: #f8ce0b; /* Gold color for stars */
}

.star-icon {
  font-size: 1.2rem;
  margin: 0 2px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .banner_area .banner_inner .banner_content h2 {
      font-size: 2rem;
  }

  .testimonial-feedback {
      font-size: 0.95rem;
  }

  .testimonial-name {
      font-size: 0.95rem;
  }

  .star-icon {
      font-size: 1rem;
  }
}
