.service-card {
  border: 1px solid hsl(0, 0%, 88%);
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #f9f9f9; /* Light gray background similar to the card */
  padding: 20px; /* Added padding for spacing */
  text-align: center; /* Center-align content */
  color: #333; /* Dark text for readability */
  font-family: 'Arial', sans-serif; /* Adjusted font family */
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.service-card .text-primary {
  color: #007bff; /* Adjusted blue color */
  font-weight: bold; /* Emphasize the text */
}

.service-card .icon {
  font-size: 40px; /* Adjust icon size */
  margin-bottom: 15px;
  color: #333; /* Match the text color */
}

.service-card .testimonial-text {
  font-style: italic; /* Italicized text for testimonial */
  margin: 10px 0;
  line-height: 1.5; /* Improve readability */
}

.service-card .star-rating {
  color: #f4c430; /* Gold color for stars */
  font-size: 18px;
  margin: 10px 0;
}

.service-card .author {
  color: #007bff; /* Blue for author name */
  font-weight: bold;
  text-decoration: none; /* Remove underline */
  display: block;
  margin-top: 10px;
}
