/* PreventiveCare.css */

.preventive-care-area {
    padding: 60px 0;
    background-color: #ffffff;
  }
  
  .section-title h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .section-title p {
    font-size: 1.1rem;
    color: #666666;
  }
  
  .preventive-service-card {
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #f8f9fa;
  }
  
  .preventive-service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .service-icon i {
    font-size: 40px;
    color: #007bff; /* Bootstrap primary color */
  }
  
  .benefits-image img {
    width: 100%;
    height: auto;
  }
  
  .benefits-content h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .benefits-list {
    list-style-type: disc;
    padding-left: 20px;
    color: #555555;
  }
  
  .learn-more {
    text-decoration: none;
    color: #fff;
  }
  
  .learn-more:hover {
    text-decoration: none;
    color: #fff;
  }
  
  .related-testimonials .testimonial-card {
    border: none;
    background: none;
  }
  
  .testimonial-photo {
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
  
  .testimonial-feedback {
    font-size: 1rem;
    color: #555555;
    margin-top: 15px;
    font-style: italic;
  }
  
  .testimonial-name {
    font-size: 1rem;
    color: #007bff; /* Bootstrap primary color */
  }
  