.card{
  border: 2px solid black;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #f9f9f9; /* Light gray background similar to the card */
  padding: 20px; /* Added padding for spacing */
  text-align: center; /* Center-align content */
  color: #333; /* Dark text for readability */
  font-family: 'Arial', sans-serif; /* Adjusted font family */
}
.card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }